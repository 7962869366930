* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

@font-face {
    font-family: Poppins-Medium;
    src: url(../../../public/assets/fonts/Poppins-Medium.ttf) format(truetype);

    /* src: url(../../public/fonts/Poppins-Medium.ttf) format(truetype); */
}

@font-face {
    font-family: Poppins-SemiBold;
    src: url(../../../public/assets/fonts/Poppins-SemiBold.ttf) format(truetype);
}

@font-face {
    font-family: Poppins-Bold;
    src: url(../../../public/assets/fonts/Poppins-Bold.ttf) format(truetype);
}

@font-face {
    font-family: Poppins-Regular;
    src: url(../../../public/assets/fonts/Poppins-Regular.ttf) format(truetype);
}

@font-face {
    font-family: Poppins-Light;
    src: url(../../../public/assets/fonts/Poppins-Light.ttf) format(truetype);
}


@media (max-width: 5120px) {
    .container-new-big {
        max-width: 100%;
        padding: 0px 80px;
    }
}

@media (max-width: 2000px) {
    .container-new-big {
        max-width: 100%;
        padding: 0px 80px;
    }
}

@media (max-width: 1800px) {
    .container-new-big {
        max-width: 100%;
        padding: 0px 80px;
    }
}

@media (max-width: 1600px) {
    .container-new-big {
        max-width: 100%;
        padding: 0px 80px;
    }
}

@media (max-width: 1400px) {
    .container-new-big {
        max-width: 100%;
        padding: 0px 80px;
    }
}

@media only screen and (max-width: 1300px) {
    .container-new-big {
        padding: 0 60px;
    }
}

@media only screen and (max-width: 1200px) {
    .container-new-big {
        padding: 0 40px;
    }

}

@media only screen and (max-width: 560px) {
    .container-new-big {
        padding: 0 20px;
    }
}

/* ============================navbar=====css============ */
.acwcard_landing_container .section,
.acwcard_landing_container .container {
    max-width: 1680px;
    margin: 0 auto;
}

.react-multi-carousel-item {
    /* display: flex;
    justify-content: center; */
}

.about_login_sec {
    max-width: 1680px;
    margin: 0 auto;
}

html {
    scroll-behavior: inherit !important;
    scroll-padding-top: 50px;
    scroll-behavior: smooth;
}

header {
    background-color: #f7f5fc;
}

header .nav-link {
    color: #2f2f2f;
    padding: 0px 12px;
    font-family: Poppins-SemiBold;
    font-size: 16px;
    margin-top: 20px;
    margin: 10px 7px 0px;
}

header .acw_navbar {
    align-items: center;
}

header .navbar-brand {
    width: 130px;
    height: 110px;
}

header .navbar-brand img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

header .acw_navbar .order_card button {
    background-color: unset;
    border: none;
    color: #2f2f2f;
    border-radius: 0px;
}

.pwd_icons .password_input {
    padding-right: 40px;
}

header .acw_navbar .order_card button:focus {
    box-shadow: none !important;
}

.active_header {
    background-color: #00aeef !important;
    /* border-radius: 4px; */
    color: #fff !important;
    padding: 5px 12px !important;
}

button:focus {
    box-shadow: none !important;
}

/* header .acw_navbar .order_card button:hover {
    background-color: white !important;
    border: 1px solid #2f2f2f !important;
    color: #2f2f2f !important;
} */
/* header .acw_navbar .order_card button:active {
    background-color: unset !important;
} */
header .acw_navbar .order_card button:focus {
    /* background-color: unset !important; */
    box-shadow: none !important;
}

.enquiry_contact_btn {
    text-align: center;
}

.enquiry_contact_btn button {
    background-color: #00aeef !important;
    border: none;
    padding: 10px 30px;
    font-family: Poppins-SemiBold;
}

a.login_btn {
    padding: 5px 12px;
    color: #2f2f2f;
    /* border-radius: 5px; */
    margin-left: 7px;
}

/* header .acw_navbar .login_btn button {
    color: #2f2f2f;
    background: none !important;
    border: 1px solid #2f2f2f;
} */

.product_section {
    background-color: #f7f5fc;
    padding-top: 80px;
    padding-bottom: 50px;
}

header .acw_navbar .login_btn button:hover {
    background-color: #00aeef !important;
    color: white !important;
    border: 1px solid #00aeef;
}

header .dropdown-item {
    font-family: Poppins-Regular;
    font-size: 14px;
}

.css-b62m3t-container {
    border-radius: 13px;
}

header .dropdown-item:hover {
    background-color: #00aeef;
    color: white;
}

/* .dropdown:hover>.dropdown-menu {
    display: block;
} */
.accout_content {
    font-size: 16px;
    text-align: center;
    color: #fff;
    font-family: Poppins-Medium;
    margin-bottom: 30px;
}

.contact_sec .form_part .form-control:focus {
    border-color: #dee2e6;
    box-shadow: none;
}

/* ====================order======sec======= */

.react_videoplayer_landing video {
    width: 100%;
    height: 700px;
}

.react_videoplayer_landing video {
    width: 100% !important;
    height: 100% !important;
}

.react_videoplayer_innerpage {
    width: 100% !important;
    height: 100% !important;
}

.react_videoplayer_innerpage video {
    width: 100%;
    height: 560px;
}

.react_videoplayer_innerpage video {
    width: 100% !important;
    height: 100% !important;
}

.banner_order_sec {
    padding-bottom: 100px;
    background-color: #f7f5fc;
    padding-top: 80px;
}

.order_section {
    text-align: center;
}

.digitalia_sec {
    background-color: #fff;
}

.digitalia_sec .digitalia_para {
    padding: 35px 0px;
}

.order_section p {
    font-family: Poppins-SemiBold;
    font-size: 18px;
}

.order_section h2 {
    font-family: Poppins-SemiBold;
    font-size: 60px;
}

.order_section .playstore_images {
    display: flex;
    justify-content: center;
    margin: 20px 0px;
}

.order_section .playstore_images .app_store_img {
    width: 140px;
    height: 45px;
}

.order_section .playstore_images .app_store_img img {
    width: 100%;
    height: 100%;
}

.order_section .playstore_images .google_play_img {
    width: 140px;
    height: 45px;
}

.order_section .playstore_images .google_play_img img {
    width: 100%;
    height: 100%;
}

.order_section .google_play_img {
    margin-left: 15px;
}

.website_btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.website_btn a {
    width: 140px;
    height: 45px;
    margin-top: 40px;
    font-family: Poppins-SemiBold;
    background: none;
    color: #00aeef;
    /* border: 1px solid #00aeef !important; */
    display: inline-block;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
}

.website_btn a:hover {
    /* background-color: #00aeef !important; */
    color: white;
}

.website_btn1 a {
    width: 140px;
    height: 45px;
    margin-top: 40px;
    font-family: Poppins-SemiBold;
    background: none;
    color: #00aeef;
    border: 1px solid #00aeef !important;
    display: inline-block;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 7px;
}

.website_btn1 a:hover {
    background-color: #00aeef !important;
    color: white;
}

/* =======================explore====sec===== */
.explore_sec {
    text-align: center;
    width: 80%;
    margin: 0 auto;
    padding: 80px 0px;
}

.explore_contain .container {
    border-bottom: 1px solid #ccc;
}

.explore_sec h2 {
    font-family: Poppins-SemiBold;
    font-size: 40px;
}

.explore_sec button {
    width: 140px;
    height: 45px;
    margin-top: 40px;
    font-family: Poppins-SemiBold;
    background: none;
    color: #00aeef;
    border: 1px solid #00aeef !important;
}

.explore_sec button:hover {
    background-color: #00aeef !important;
}

.explore_sec p {
    font-family: Poppins-Regular;
    font-size: 18px;
    padding-top: 30px;
}

/* =======digitalia_card====== */
.digitalia_card {
    padding: 80px 0px;
}

.digitalia_card .digitalia h2 {
    font-family: Poppins-SemiBold;
    font-size: 40px;
    text-align: center;
    padding-bottom: 40px;
}

.digitalia_card .digitalia .card_sec_digitalia .digi_card_img {
    width: 100%;
    height: 210px;
}

.digitalia_card .digitalia .card_sec_digitalia .digi_card_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.digitalia_card .digitalia .card_sec_digitalia .digi_card_sub {
    font-family: Poppins-Medium;
    font-size: 26px;
    line-height: 35px;
}

.digitalia_card .digitalia .card_sec_digitalia .digitalia {
    padding: 0px;
    list-style-type: none;
}

.digitalia_card .digitalia .card_sec_digitalia .digitalia li {
    font-family: Poppins-Medium;
    font-size: 18px;
    display: inline-flex;
    padding: 10px 0px;
}

.digitalia_card .digitalia .card_sec_digitalia .digitalia li span svg {
    margin-right: 10px;
    fill: #00aeef;
}

.last_card_div {
    display: flex;
    align-items: flex-end;
}

/* ================product_section=========== */
.product_section .tab_1 .nav-tabs {
    justify-content: center;
    border-bottom: none;
}

.product_section .tab_1 .nav-tabs .nav-item .nav-link {
    width: 90px;
    height: 45px;
    border: 1px solid #ccc;
    text-align: center;
    font-family: Poppins-SemiBold;
    font-size: 16px;
    margin: 0px 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2f2f2f;
}

.product_section .tab_1 .nav-tabs .nav-item .nav-link.active {
    background-color: #00aeef;
    color: white;
}

.product_section .nav-fill .nav-item,
.nav-fill>.nav-link {
    flex: initial;
}

.product_section h2 {
    font-family: Poppins-SemiBold;
    font-size: 40px;
    text-align: center;
    padding-bottom: 30px;
}

.product_section .product_card_image {
    width: 100%;
    height: 210px;
}

.product_section .product_card_image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.product_section .cost_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product_section .cost_btn button {
    width: 140px;
    height: 45px;
    margin-top: 40px;
    font-family: Poppins-SemiBold;
    background: none;
    color: #2f2f2f;
    border: 1px solid #2f2f2f;
    margin: 10px;
}

.product_section .cost_btn button:hover {
    background-color: #00aeef !important;
    color: white;
    border: 1px solid #00aeef;
}

.product_section .tab_inner_page h4 {
    font-family: Poppins-Regular;
    font-size: 35px;
    margin-bottom: 20px;
}

.product_section .tab_inner_page p {
    font-family: Poppins-Regular;
    font-size: 16px;
}

.product_section .cost_btn .cost_amo {
    font-family: Poppins-Regular;
    font-size: 35px;
    display: flex;
    align-items: center;
}

.product_section .cost_btn .active_btn {
    border: 1px solid #00aeef;
    color: #00aeef;
}

.carosel_sec .caro_item {
    width: 270px;
    height: 170px;
    /* border-radius: 25px; */
}

.carosel_sec .react-multiple-carousel__arrow--left {
    left: calc(0% + 1px);
}

.carosel_sec .react-multiple-carousel__arrow--right {
    right: calc(1% + 1px);
}

.digitalia_sec .react-multiple-carousel__arrow--left {
    left: calc(0% + 1px);
}

.digitalia_sec .react-multiple-carousel__arrow--right {
    right: calc(1% + 1px);
}

.carosel_sec .caro_item img {
    width: 100%;
    height: 100%;
    border-radius: 12px;
    object-fit: contain;
}

/* ====================about_section========== */
.about_section {
    padding: 90px 0px;
    background-color: #212121;
    color: white;
}

.about_section .about_sec {
    width: 100%;
}

.about_section .about_sec h2 {
    font-family: Poppins-SemiBold;
    font-size: 40px;
    padding-bottom: 30px;
}

.about_section .about_sec p {
    font-family: Poppins-Regular;
    font-size: 18px;
    padding-top: 0;
    margin-bottom: 40px;
}

.about_section .about_sec .about_ul {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
}

.about_section .about_sec .about_ul li {
    padding: 15px 0px;
    width: 100%;
    display: inline-flex;
    font-size: 18px;
    font-family: 'Poppins-Medium';

}

.about_section .about_sec .about_ul li svg {
    fill: #00aeef;
    margin-right: 10px;
}

.about_section .about_sec .about_ul li .about_list_span {
    border-bottom: 1px solid white;
    padding-bottom: 15px;
    display: inline-block;
    width: 100%;
}

.about_section .about_sec button {
    width: 140px;
    height: 45px;
    margin-top: 40px;
    font-family: Poppins-SemiBold;
    background: none;
    color: white;
    border: 1px solid #00aeef;
    margin-left: 20px;
}

.about_section .about_sec button:hover {
    background-color: #00aeef;
    color: #fff;
}

.about_section .about_img1 {
    width: 100%;
    height: 220px;
}

.about_section .about_img1 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.about_section .about_img2 {
    width: 100%;
    height: 600px;
}

.about_section .about_img2 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.about_section .about_card_pic {
    margin-top: 7rem;
}

.about_section .mob_pic {
    position: relative;
    right: 57px;
    top: 13px;
    width: max-content;
}

/* ==============virtual_card=============== */
.virtual_sec {
    background-color: #f7f5fc;
    padding: 90px 0px;
}

.virtual_sec .virtual_image {
    width: 100%;
    height: 400px;
}

.virtual_sec .virtual_image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.virtual_sec .virtual_card_content h2 {
    font-family: Poppins-SemiBold;
    font-size: 40px;
    padding-bottom: 30px;
}

.virtual_sec .virtual_card_content h3 {
    font-family: Poppins-Medium;
    font-size: 25px;
    padding-bottom: 10px;
}

.virtual_sec .virtual_card_content p {
    font-family: Poppins-Regular;
    font-size: 18px;
    padding-bottom: 10px;
}

.virtual_sec .virtual_card_content button {
    width: 140px;
    height: 45px;
    margin-top: 40px;
    font-family: Poppins-SemiBold;
    background: none;
    color: #2f2f2f;
    border: 1px solid #2f2f2f;
    margin-right: 10px;
}

.virtual_sec .virtual_card_content button:hover {
    background-color: #00aeef;
    color: #fff;
    border: none;
}

.virtual_sec .virtual_card_content .explore_virtual {
    background-color: #00aeef;
    color: white;
    border: 1px solid #00aeef;
}

.virtual_sec .virtual_card_content .explore_virtual:hover {
    background-color: #f7f5fc;
    color: #2f2f2f;
    border: 1px solid #2f2f2f;
}

/* ============works_section=============== */
.works_section {
    /* width: 100%; */
    margin: 0 auto;
    padding: 120px 0px;
    overflow: hidden;
}

.screenshot-img-div {
    width: 350px;
}

.screenshot-img-div1 {
    text-align: center;
    justify-content: center;
    display: flex !important;
}

.nav-img img {
    width: 100%;
}

.screenshot-img-div img {
    width: 100%;
}

.works_section p {
    font-family: Poppins-Regular;
    font-size: 18px;
    padding-bottom: 10px;
    text-align: center;
}

.works_section h2 {
    font-family: Poppins-SemiBold;
    font-size: 40px;
    padding-bottom: 30px;
    text-align: center;
}

.works_section .video_part {
    width: 70%;
    margin: 0 auto;
    /* height: 430px; */
    padding-top: 50px;
    cursor: pointer;
}

.works_section .video_part img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* ==========pricing_content============ */
.pricing_content_text h2 {
    font-family: Poppins-SemiBold;
    font-size: 40px;
    padding-bottom: 15px;
}

.pricing_content_sub {
    font-family: Poppins-Regular;
    font-size: 18px;
    padding-bottom: 15px;
}

.pricing_content_box {
    padding: 10px 25px;
    width: 200px;
    border: 1px solid white;
    font-family: Poppins-SemiBold;
    font-size: 21px;
}

.pricing_content_text button {
    width: fit-content;
    height: 45px;
    margin-top: 40px;
    font-family: Poppins-SemiBold;
    background: none;
    color: white;
    border: 1px solid #00aeef;
    background-color: #00aeef;
    margin-right: 10px;
    font-size: 16px;
}

.pricing_sec {
    background-color: #2f2f2f;
    color: white;
    padding: 100px 0px;
}

.pricing_sec .pricing_content .pricing_images {
    width: 100%;
    height: 460px;
}

.pricing_sec .pricing_content .pricing_images img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* =====================bulk_order_sec=========== */
.bulk_order_sec {
    padding: 110px 0px 120px;
    background-image: url('../../../public/assets/images/acwcard/bulkbg1.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: #f7f5fc;
    background-size: contain;
    background-color: #f1eef7;
}

.back-img-color {
    background-color: unset !important;
    padding-bottom: 100px !important;
}

.bulk_order_sec .bulk_order {
    text-align: center;
    width: 60%;
    margin: 0 auto;
}

.bulk_order_sec .bulk_order h2 {
    font-family: Poppins-SemiBold;
    font-size: 40px;
    padding-bottom: 15px;
}

.bulk_order_sec .bulk_order p {
    font-family: Poppins-Regular;
    font-size: 18px;
    padding-bottom: 15px;
}

.bulk_order_sec .bulk_order button {
    width: fit-content;
    height: 45px;
    font-family: Poppins-SemiBold;
    background: none;
    color: white;
    border: 1px solid #00aeef;
    background-color: #00aeef;
    margin-right: 10px;
    font-size: 16px;
}

.bulk_order_sec .bulk_order button:active {
    background-color: #00aeef;
}

.bulk_order_sec .bulk_order button:hover {
    background-color: unset;
    color: #00aeef;
}

/* ===============testimonials_sec========== */
.testimonials_sec .testimonials {
    padding: 100px 0px;
}

.testimonials_sec .testimonials h2 {
    font-family: Poppins-SemiBold;
    font-size: 40px;
    padding-bottom: 15px;
    text-align: center;
}

.about-bulk-btn:hover {
    color: #fff !important;
}

a.acw-txt {
    color: #000;
    text-decoration: none;
}

.testimonials_sec .testimonials .caro_item {
    width: 350px;
    height: 285px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 10px;
    padding: 22px;
    margin: 10px;
}

.testimonials_sec .testimonials .react-multiple-carousel__arrow--left {
    left: calc(0% + 1px);
    /* display: none; */
}

.testimonials_sec .testimonials .react-multiple-carousel__arrow--right {
    right: calc(0% + 1px);
    /* display: none; */
}

.testimonials_sec .testimonials .caro_item img {
    width: 100%;
    height: 100%;
}

.testimonials_sec .testimonials .caro_item .para {
    font-family: Poppins-Regular;
    font-size: 16px;
    padding-bottom: 15px;
    margin-bottom: 0px;
}

.testimonials_sec .testimonials .prof_name {
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 30px;
    width: 310px;
}

.testimonials_sec .testimonials .prof_name .prof_image {
    width: 50px;
    height: 50px;
}

.testimonials_sec .testimonials .prof_name .prof_image img {
    width: 100%;
    height: 100%;
}

.testimonials_sec .testimonials .prof_name .prof_address .add_1 {
    font-family: Poppins-Medium;
    font-size: 14px;
    margin-bottom: 0px;
}

/* ==================get_start_sec========= */
.get_start_sec {
    background-color: #f7f5fc;
    padding-top: 62px;
}


.get_start_sec .left_start h2 {
    font-family: Poppins-SemiBold;
    font-size: 40px;
    padding-bottom: 15px;
}

.get_start_sec .left_start p {
    font-family: Poppins-Regular;
    font-size: 18px;
    padding-bottom: 15px;
}

.get_start_sec .left_start button {
    width: 140px;
    height: 45px;
    font-family: Poppins-SemiBold;
    background: none;
    color: #00aeef;
    border: 1px solid #00aeef;
}

.get_start_sec .right_start {
    width: auto;
    height: 350px;
}

.get_start_sec .right_start img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.enquiry_image_right{
    margin-left: 40px;
}
.get_start_sec .left_cre {
    display: flex;
    align-items: center;
}

/* =================demo_sec================ */
.demo_sec {
    padding: 100px 0px;
}

.demo_sec .demo {
    width: 60%;
    margin: 0 auto;
    text-align: center;
}

.demo_sec .demo h2 {
    font-family: Poppins-SemiBold;
    font-size: 40px;
    padding-bottom: 15px;
}

.demo_sec .demo p {
    font-family: Poppins-Regular;
    font-size: 18px;
    padding-bottom: 15px;
}

.demo_sec .demo .form-control {
    border-radius: 12px;
    padding: 10px;
}

.demo_sec .demo .form-control:focus {
    box-shadow: none;
    border: 1px solid #ccc;
}

.demo_sec .demo .form-select:focus {
    box-shadow: none;
    border: 1px solid #ccc;
}

.demo_sec .demo .form-select {
    border-radius: 12px;
    padding: 10px;
}

.demo_sec .demo input::placeholder {
    font-family: Poppins-Regular;
    font-size: 16px;

}

.demo_sec .demo textarea::placeholder {
    font-family: Poppins-Regular;
    font-size: 16px;

}

.demo_sec .demo .form-select {
    font-family: Poppins-Regular;
    color: #2f2f2f;
    font-size: 16px;
}

.demo_sec .demo .demo_btn button {
    width: 140px;
    height: 45px;
    margin-top: 40px;
    font-family: Poppins-SemiBold;
    background: none;
    color: white;
    border: 1px solid #00aeef;
    background-color: #00aeef;
}

/* ==================footer=====css========== */
.footer_section,
.copyrighs_section {
    background-color: #f7f5fc;
}

.footer_section .footer_logo {
    width: 120px;
    height: 120px;
    margin: 0 auto;
}

.footer_section .footer_logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.footer_section .footer_ul {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
    text-align: center;
    margin-bottom: 40px;
}

.footer_section .footer_ul li {
    padding: 0px 30px;
    display: inline-block;
}

.footer_section .footer_ul li a {
    text-decoration: none;
    color: #2f2f2f !important;
    font-family: Poppins-Medium;
    font-size: 16px;
}

.footer_section .footer_content {
    width: 80%;
    margin: 0 auto;
}

.footer_section .footer_content h5 {
    font-family: Poppins-SemiBold;
    font-size: 16px;
    padding-right: 0px;
    text-align: center;
}

.footer_section .footer_content ul {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
}

.footer_section .footer_content ul li {
    font-family: Poppins-Regular;
    font-size: 15px;
    padding: 5px 0px;
    text-align: center;
}

.footer_section .footer_content ul li a {
    text-decoration: none;
    color: #2f2f2f;
}

.copyrighs_section .copy_rights {
    display: block;
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.copyrighs_section .copy_rights .social_media {
    padding: 0px;
    margin: 0px;
    list-style-type: none;
    margin-bottom: 20px;
}

.copyrighs_section .copy_rights .social_media li {
    display: inline-block;
    margin: 0px 5px;
    background-color: #00aeef;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
}

.copyrighs_section .copy_rights .social_media li a svg {
    color: white;
    font-size: 20px;
    position: absolute;
    top: 10px;
    left: 10px;
}

.copyrighs_section .copy_rights p {
    font-family: Poppins-SemiBold;
    font-size: 16px;
    margin-bottom: 0;
}

.footer_section {
    padding-bottom: 30px;
}

.copyrighs_section .container {
    padding-top: 40px;
    padding-bottom: 60px;
    border-top: 1px solid #ccc;
}

.copyrighs_section .copy_rights .social_media .facebook {
    background-color: #3e5d9e;
}

.copyrighs_section .copy_rights .social_media .twiiter {
    background-color: #00b7f6;
}

.copyrighs_section .copy_rights .social_media .youtube {
    background-color: #f13028;
}

.copyrighs_section .copy_rights .social_media .linkedinn {
    background-color: #097cbc;
}

.copyrighs_section .copy_rights .social_media .instagram {
    background-color: #f53f7b;
}

/* ======================digitalcard=====sec============= */
.digitalia_sec .digital_card_head {
    text-align: center;
}

.digitalia_sec .digitalia_card_design .card_sub {
    display: flex;
    justify-content: space-between;
}

.digitalia_sec .digital_card_btn_1 {
    width: 140px;
    height: 45px;
    margin-top: 40px;
    font-family: Poppins-SemiBold !important;
    color: #2f2f2f !important;
    border: 1.5px solid #2f2f2f !important;
    margin: 10px;
    background: none !important;
}

.digitalia_sec .digital_card_btn_1:hover {
    background-color: #00aeef !important;
    color: #fff !important;
    border: none !important;
}

.digitalia_sec .digital_card_btn_2 {
    width: 140px;
    height: 45px;
    margin-top: 40px;
    font-family: Poppins-SemiBold !important;
    color: #fff !important;
    border: 1.5px solid #00aeef !important;
    margin: 10px;
    background: #00aeef !important;
}

.digitalia_sec .digital_card_btn_2:hover {
    background-color: unset !important;
    color: #2f2f2f !important;
}

.digitalia_sec .card_sub_1 {
    font-family: Poppins-SemiBold !important;
    font-size: 22px;
}

.digitalia_sec .card_sub_1 p {
    font-family: Poppins-Regular;
    font-size: 18px;
}

.digitalia_sec .card_sub_2 .cost_amo {
    font-family: Poppins-Light;
    font-size: 35px !important;
    display: flex;
    align-items: center;
}

.related-produt span.ruppee_symbol {
    display: flex;
}

.black_metal_section span.ruppee_symbol {
    display: flex;
}

.black_metal_section h6.black-metal-rs {
    font-size: 35px;
    font-family: Poppins-Light;
    color: #2f2f2f;
    margin-bottom: 0;
    display: flex;
    align-items: center;
}

.related-produt h6.rupees_card {
    color: #2f2f2f;
    font-size: 35px;
    font-family: 'Poppins-Light';
    display: flex;
    align-items: center;
}

.digitalia_sec .digitalia_card_design .card {
    background-color: #f7f5fc;
    border: none;
    width: 100%;
}

img.card-img-top {
    height: 220px;
}

/* ===================contact===us============ */
.contact_sec {
    padding-bottom: 100px;
}

.contact_sec .form_part {
    width: 50%;
    margin: 0 auto;

}

.contact_sec .contact_text1 {
    text-align: center;
    padding: 50px 0px;
}

.contact_sec .contact_text1 h2 {
    font-family: Poppins-SemiBold !important;
}

.contact_sec .contact_text1 p {
    font-family: Poppins-Regular !important;
}

.contact_sec .contact_text1 a {
    font-family: Poppins-SemiBold !important;
    text-decoration: none;
    color: #2f2f2f;
}

.contact_sec .sub_text_contact {
    text-align: center;
    padding: 20px 0px;
}

.contact_sec .sub_text_contact h3 {
    font-family: Poppins-SemiBold !important;
    font-size: 30px;
}

.contact_sec .sub_text_contact p {
    font-family: Poppins-Regular !important;
    font-size: 18px;
}

.contact_sec .contact_btn {
    text-align: center;
}

.contact_sec .contact_btn button {
    width: 140px;
    height: 45px;
    margin-top: 40px;
    font-family: Poppins-SemiBold !important;
    color: #fff !important;
    border: 1px solid #00aeef !important;
    margin: 10px;
    background: #00aeef !important;
}

.contact_sec .form-label {
    font-family: Poppins-Medium;
    font-size: 16px;
}

.contact_sec .contact_sub_div {
    font-family: Poppins-Regular !important;
}

/* =======================media query==================== */



/* ajith css */

/* pricing css */

.pricing_section {
    background-color: #f7f5fc;
}

.pricing_section h2.pricing-txt {
    font-size: 35px;
    color: #2f2f2f;
}

.pricing_section p.pricing-para {
    color: #2f2f2f;
}

.pricing_section .container {
    border: none;
}

/* black metal css */

.black_metal_section {
    padding: 85px 0px;
}

.black_metal_section .for_me .row {
    width: 100%;
}

.black_metal_section .nav-tabs .nav-item .nav-link {
    width: 170px;
    height: 45px;
    border: 1px solid #9d9d9d;
    text-align: center;
    font-family: Poppins-Medium;
    font-size: 18px;
    margin: 0px 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2f2f2f;
}

.black_metal_section .nav-tabs .nav-item .nav-link.active {
    background-color: #00aeef;
    color: white;
}

.black_metal_section li.nav-item {
    flex: initial;
}

.black_metal_section .nav-tabs {
    justify-content: center;
    border: none;
}

.black_metal_section .card_img_left {
    width: 550px;
}

.black_metal_section .card_img_left img {
    width: 100%;
}

.black_metal_section .black_meatl_first {
    display: flex;
    padding-right: 20px;
}

.black_metal_section .card-detail {
    padding-left: 10px;
}

.black_metal_section .black-metal-professional {
    padding-left: 20px;
}

.black_metal_section h5.black-metal-txt {
    color: #2f2f2f;
    font-size: 22px;
    font-family: 'Poppins-SemiBold';
}

.black_metal_section h6.black-metal-txt2 {
    font-size: 18px;
    font-family: 'Poppins-Regular';
    color: #3c3c3c;
}

.black_metal_section p.black-metal-para {
    font-size: 16px;
    font-family: 'Poppins-Regular';
    color: #2f2f2f;
}

.black_metal_section h6.black-metal-rs {
    font-size: 35px;
    font-family: Poppins-Light;
    color: #2f2f2f;
    margin-bottom: 0;
}

.black_metal_section span.all-tax {
    font-size: 12px;
    font-family: 'Poppins-Regular';
    color: #2f2f2f;
}

.black_metal_section .under_black_metal {
    display: flex;
}

.black_metal_section .left_under_black {
    width: 145px;
    /* margin-left: 10px; */
}

.black_metal_section .left_under_black img {
    width: 100%;
}

.black_metal_section .blac_metal {
    border-right: 1px solid #dedede;
}

.black_metal_section p.printed-para {
    font-size: 16px;
    font-family: 'Poppins-Medium';
    color: #2f2f2f;
    margin-bottom: 25px;
}

.black_metal_section input.form-control {
    margin-bottom: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    padding: 10px 20px;
    font-family: 'Poppins-Light';
    font-size: 14px;
    border-radius: 10px;
}

.demo_sec .css-13cymwt-control {
    background: #fff !important;
    min-height: auto !important;
    border: 1px solid #868686 !important;
}

.demo_sec .css-13cymwt-control:hover {
    border: 1px solid #868686 !important;
}

.demo_sec .css-t3ipsp-control {
    border: 1px solid #868686 !important;
}

.demo_sec .css-t3ipsp-control:hover {
    border: 1px solid #868686 !important;
}

.demo_sec .css-t3ipsp-control:active {
    border: 1px solid #868686 !important;
}

.demo_sec .demo .form-control:focus {
    box-shadow: none !important;
    border: 1px solid #868686 !important;
    outline: none !important;
}

.black_metal_section .form-control:focus {
    border: 1px solid #868686 !important;
    outline: 1px solid #868686 !important;
    box-shadow: none !important;

}

.black_metal_section button#buy-now {
    font-size: 18px;
    font-family: 'Poppins-SemiBold';
    background-color: #00aeef;
    border: none;
    padding: 7px 30px;
}

.black_metal_section .tab-content {
    margin-top: 95px;
}

.black_metal_section .buy_now_btbn {
    margin-top: 20px;
}

/* related product css */

.related-produt {
    margin-bottom: 75px;
}

.related-produt .caro_item_related {
    width: 350px;
    border-radius: 10px;
}

.related-produt .caro_item_related img {
    width: 100%;
    height: 220px;
    border-radius: 12px;
}

.related-produt .under_item {
    width: 350px;
    background-color: #f7f5fc;
    padding: 40px 20px 20px;
}

.related-produt .black_metal_professional {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.related-produt .react-multiple-carousel__arrow {
    position: absolute;
    outline: 0;
    transition: all .5s;
    border-radius: 35px;
    z-index: 1000;
    border: 1px solid #999999;
    background: #fff;
    min-width: 43px;
    min-height: 43px;
    opacity: 1;
    cursor: pointer;
}

.related-produt .react-multiple-carousel__arrow--left {
    top:0px;
    left: 88%;

}

.related-produt .react-multiple-carousel__arrow::before {
    color: #999999;
}

.related-produt .related_pro {
    margin-top: 60px;
}

.related-produt .react-multiple-carousel__arrow--right {
    top: 0;
}

.related-produt h5.black_metal_professional_txt {
    font-size: 22px;
    font-family: 'Poppins-SemiBold';
    color: #2f2f2f;
}

.related-produt h6.rupees_card {
    color: #2f2f2f;
    font-size: 35px;
    font-family: 'Poppins-Light';
}

.related-produt p.pvc_txt {
    font-size: 18px;
    font-family: 'Poppins-Regular';
    color: #6e6d6f;
}

.related-produt button#features-now {
    font-family: 'Poppins-Medium';
    font-size: 18px;
    background-color: #f7f5fc;
    color: #2f2f2f;
    border: 1.5px solid #9d9d9d;
    padding: 5px 30px;
    margin: 10px;
}

.related-produt button#order-now {
    background-color: #00aeef;
    color: #fff;
    border: none;
    font-size: 18px;
    font-family: 'Poppins-SemiBold';
}

.related-produt .related_btn {
    text-align: center;
    margin-top: 45px;
}

h5.related-product-txt {
    margin-bottom: 0;
    font-size: 22px;
    font-family: 'Poppins-SemiBold';
}

.related-produt button#features-now:hover {
    background-color: #00aeef;
    color: #fff;
}

.related-produt button#order-now:hover {
    background-color: #f7f5fc;
    color: #2f2f2f;
    border: 1px solid #2f2f2f;
}

/* .related-produt .react-multiple-carousel__arrow:hover {
    background: #00aeef;
    border: none;
} */

/* ========about_section====== */
.prof_name2 {
    margin-top: 25px;
}

.about_page_sec .aboutcard_card_img {
    width: 100%;
    height: 260px;
    margin-bottom: 40px;
}

.about_page_sec .aboutcard_card_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.about_page_sec .about_part .about_hede_content {
    text-align: center;
    color: #2f2f2f;
}

.about_page_sec .about_hede_content h3 {
    font-family: 'Poppins-SemiBold';
    font-size: 30px;
}

.about_page_sec .about_hede_content p {
    font-family: 'Poppins-Medium';
    font-size: 18px;
    padding: 30px 0px;
}

.about_card_box p {
    margin-bottom: 0;
}

.about_page_sec .about_part {
    padding: 100px 0px;
    padding-top: 150px;
}

.about_page_sec .about_card_content .about_card_box {
    border: 2px solid #00aeef;
    padding: 10px 20px;
    font-family: 'Poppins-SemiBold';
    font-size: 18px;
    border-radius: 5px;
}

.about_login_sec {
    background-color: #f7f5fc;
    height: 425px;
    width: 100%;
}

.about_login_sec .caro_item .about_count {
    width: 20%;
    float: left;
    height: 300px;
}

.about_login_sec .caro_item .about_count p {
    width: 60px;
    height: 60px;
    background-color: #00aeef;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    font-family: 'Poppins-SemiBold';
    font-size: 40px;
}

.about_login_sec .caro_item .about_caro_content {
    width: 60%;
    position: relative;
}

.about_login_sec .caro_item .about_caro_content h3 {
    font-family: 'Poppins-SemiBold';
    font-size: 30px;
    margin-bottom: 20px;
}

.about_login_sec .caro_item .about_caro_content p {
    font-family: 'Poppins-Medium';
    font-size: 18px;
}

.about_login_sec .caro_item {
    display: inline-flex;
    padding: 70px 0px;
}

.about_login_sec {
    margin-bottom: 130px;
}

.about_login_sec .react-multiple-carousel__arrow--left {
    position: absolute;
    bottom: 70px;
    left: 112px !important;
}

.about_login_sec .react-multiple-carousel__arrow--right {
    position: absolute;
    bottom: 70px;
    right: 63%;
}

.about_login_sec .react-multiple-carousel__arrow {
    background-color: #322e61;
}

.about_login_sec .login_mob_img {
    width: 260px;
    height: 530px;
    position: relative;
    top: -50px;
}

.about_login_sec .login_mob_img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

/* ==================ready_section======================== */
.ready_section {
    background-color: #212121 !important;
    color: white;
}

.ready_section .bulk_order {
    width: 50%;
}

.ready_section {
    padding: 110px 0px 120px;
    background-image: url('../../../public/assets/images/acwcard/loginaboutcardname.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-color: #f7f5fc;
    background-size: contain;
    background-color: #f1eef7;
}

/* ===================product_sec========= */
.product_sec .realted_head_text {
    font-family: 'Poppins-SemiBold' !important;
    font-size: 22px !important;
}

.product_sec .tab_1 {
    position: relative;
}

.product_sec .react-multiple-carousel__arrow--left {
    position: absolute;
    top: 10px;
    left: 92% !important;
}

.product_sec .react-multiple-carousel__arrow--right {
    position: absolute;
    top: 10px;
    right: 0px !important;
}

.product_sec .react-multiple-carousel__arrow {
    background-color: #fff;
    border: 1px solid #999999;
}

.product_sec .react-multiple-carousel__arrow::before {
    color: #999999;
}

.product_sec .react-multi-carousel-list {
    padding-top: 100px;
}

.product_sec .realted_head_text {
    position: absolute;
    top: 20px;
    left: 20px;
}

/* =======about_bulk_sec=== */
.about_bulk_sec {
    background-color: #00aeef !important;
    background-image: none !important;
    color: white;
    padding: 100px 0px !important;
}

.about_bulk_sec .bulk_order button {
    border: 1px solid #fff !important;
}

.about_bulk_sec .bulk_order {
    width: 60%;
}


@media (min-width:1450px) {
    .about_login_sec .react-multiple-carousel__arrow--right {
        right: 71% !important;
    }

    .black_metal_section .left_under_black {
        width: 164px;
    }
}

@media (max-width:1450px) {
    .react_videoplayer_innerpage {
        height: auto;
    }

    .react_videoplayer_innerpage video {
        height: auto;
    }
}

@media (max-width:1112px) {
    .react_videoplayer_innerpage {
        height: 560px;
    }

    .black_metal_section .left_under_black {
        width: 120px;
    }

    .related-produt .react-multiple-carousel__arrow--left {
        left: 86%;
    }

    .related-produt .caro_item_related {
        width: 300px;
    }

    .related-produt .under_item {
        width: 300px;
    }

    .related-produt .black_metal_professional {
        display: block;
    }

    .contact_sec .form_part {
        width: 100%;
        padding: 0px 20px;
    }

    .about_login_sec .react-multiple-carousel__arrow--right {
        right: 270px !important;
    }

    .about_login_sec .react-multiple-carousel__arrow--left {
        left: 90px !important;
    }

    .product_sec .react-multiple-carousel__arrow--left {
        left: 90% !important;
    }
}

@media (max-width:1024px) {
    .carosel_sec .caro_item {
        width: auto;
    }

    .react_videoplayer_innerpage {
        height: auto;
    }

    .react_videoplayer_innerpage video {
        height: auto;
    }

    .digitalia_sec .digital_card_btn_2 {
        width: 100%;
    }

    .digitalia_sec .digital_card_btn_1 {
        width: 100%;
    }

    .related-produt .caro_item_related {
        width: 460px;
    }

    .related-produt .under_item {
        width: 460px;
    }

    .about_login_sec .react-multiple-carousel__arrow--left {
        bottom: 50px;
    }

    .about_login_sec .react-multiple-carousel__arrow--right {
        bottom: 50px;
    }
}

@media (max-width:450px) {
    .black_metal_section .nav-tabs .nav-item .nav-link {
        margin-bottom: 10px;
    }
}

@media (max-width:992px) {
    .black_metal_section {
        padding: 60px 0px;
    }

    .black_metal_section .black-metal-professional {
        padding: 10px;
    }

    .login_div {
        margin: 40px 0px;
    }



    .digitalia_sec .digitalia_card_design {
        margin: 0px 5px;
    }

    .navbar-light .navbar-nav .nav-link {
        width: 100%;
    }

    .black_metal_section .black_meatl_first {
        text-align: center;
    }

    .black_metal_section h6.black-metal-rs {
        justify-content: center;
    }

    .about_section .about_sec .about_ul li {
        display: inline-flex !important;
        width: 100%;
    }

    .black_metal_section .buy_now_btbn {
        text-align: center;
    }

    .digitalia_sec .digitalia_card_design .card {
        width: 100% !important;
    }



    header .acw_navbar .order_card button {
        text-align: left;
        border-radius: 0px !important;
    }

    header .acw_navbar .order_card button:active {
        background-color: unset !important;
    }

    a.login_btn {
        border: none;
        border-radius: 0px;
    }

    .contact_sec .form_part {
        width: 100%;
        padding: 0px 20px;
    }

    .pricing_section p.pricing-para {
        text-align: center;
    }

    .about_page_sec .aboutcard_card_img {
        height: auto;
        margin-bottom: 10px;
    }

    .about_page_sec .about_hede_content p {
        text-align: center;
    }

    .about_login_sec {
        height: auto;
    }

    .ready_section .bulk_order {
        width: 100%;
    }

    .about_bulk_sec .bulk_order {
        width: 100%;
    }

    .about_page_sec .about_part {
        padding: 80px 0px;
    }

    .about_login_sec .caro_item .about_caro_content {
        width: 100%;
        margin-bottom: 70px;
    }

    .about_login_sec .caro_item .about_count {
        width: 25%;
        margin: 0 auto;
    }

    .about_login_sec .login_mob_img {
        margin: 0 auto;
    }

    .about_login_sec .caro_item {
        display: flex;
        padding: 70px 0px;
        flex-direction: column;
        justify-content: center;
    }

    .about_login_sec .caro_item .about_count {
        height: auto;
    }

    .about_login_sec .react-multiple-carousel__arrow--left {
        left: 32% !important;
    }

    .about_login_sec .react-multiple-carousel__arrow--right {
        left: 44% !important;
    }

    .about_login_sec .react-multiple-carousel__arrow--right {
        bottom: 73px;
    }

    .about_login_sec .react-multiple-carousel__arrow--left {
        bottom: 73px;
    }

    .product_sec .react-multiple-carousel__arrow--right {
        right: 2% !important;
    }

    .product_sec .react-multiple-carousel__arrow--left {
        left: 87% !important;
    }

    a.order_card {
        margin-top: 14px;
        width: 100%;
    }

    a.login_btn {
        margin-top: 14px;
    }

    .about_page_sec .about_card_content .about_card_box {
        height: 105px;
    }

    .about_login_sec {
        margin-bottom: 0;
    }
}

@media (max-width:991px) {

    .black_metal_section .blac_metal {
        border-right: none;
    }

    .related-produt .caro_item_related {
        width: 340px;
    }

    .related-produt .under_item {
        width: 340px;
    }

    .related-produt .react-multiple-carousel__arrow--left {
        left: 82%;
    }

    .black_metal_section .left_under_black {
        width: 185px;
    }

    .black_metal_section .card-detail {
        margin-top: 45px;
    }

    .about_page_sec .about_card_content .about_card_box {
        height: auto;
    }

    .about_login_sec .caro_item .about_caro_content h3 {
        text-align: center;
        margin-top: 20px;
    }

    .about_login_sec .caro_item .about_count p {
        margin: auto;
    }

    .about_login_sec .caro_item .about_caro_content p {
        text-align: center;
    }

    .react-multiple-carousel__arrow--right {
        width: 40px;
    }

    .about_login_sec .react-multiple-carousel__arrow--right {
        left: 53% !important;
    }

    .about_login_sec .react-multiple-carousel__arrow--left {
        left: 42% !important;
    }

    .product_sec .react-multiple-carousel__arrow--left {
        left: 84% !important;
    }

    .digitalia_sec .digitalia_card_design .card_sub {
        display: block;
    }
}

@media (max-width:932px) {
    .digitalia_sec .digital_card_btn_2 {
        width: 100%;
    }

    .digitalia_sec .digital_card_btn_1 {
        width: 100%;
    }

    .about_card_box p {
        text-align: center;
    }
}

@media (max-width:820px) {

    .carosel_sec .caro_item {
        width: auto;
    }

    .website_btn1 a {
        margin: auto;
    }
}

@media (max-width:768px) {

    .about_section .about_sec .about_ul li {
        display: block;
    }
}

@media (max-width:720px) {

    .about_section .about_img1 {
        height: auto;
    }

    .black_metal_section .black_meatl_first {
        display: block;
    }

    .black_metal_section .under_black_metal {
        justify-content: center;
        margin-top: 35px;
    }

    .black_metal_section .left_under_black {
        width: 246px;
    }

    .black_metal_section .card_img_left {
        width: 495px;
    }

    .related-produt .react-multiple-carousel__arrow--left {
        left: 77%;
    }

    .related-produt .caro_item_related {
        width: 250px;
    }

    .related-produt .under_item {
        width: 250px;
    }

    .footer_section .footer_content h5 {
        margin-top: 30px;
    }

    .product_sec .react-multiple-carousel__arrow--left {
        left: 79% !important;
    }
}

@media (max-width:700px) {
    .digitalia_sec .digital_card_btn_2 {
        width: 100%;
    }

    .digitalia_sec .digital_card_btn_1 {
        width: 100%;
    }

    textarea#exampleFormControlTextarea1 {
        margin-top: 30px;
    }

    .black_metal_section .tab-content {
        margin-top: 60px;
    }
}

@media (max-width:720px) {
    .related-produt .caro_item_related img {
        height: auto !important;
    }

    .related-produt .under_item {
        text-align: center;
    }

    .related-produt h6.rupees_card {
        justify-content: center;
    }

}

@media (max-width:600px) {
    .related-produt .react-multiple-carousel__arrow--left {
        left: 70%;
    }
}

@media (max-width:500px) {

    img.card-img-top {
        height: auto;
    }

    .carosel_sec .caro_item {
        margin: 0px 5px;
    }

    .about_login_sec .react-multiple-carousel__arrow--left {
        left: 40% !important;
    }

    .product_sec .react-multiple-carousel__arrow--right {
        top: 54px;
    }

    .product_sec .react-multiple-carousel__arrow--left {
        top: 54px;
    }

    .product_sec .react-multiple-carousel__arrow--left {
        left: 74% !important;
    }

    .black_metal_section .card-detail {
        padding-left: 0;
    }

    .black_metal_section .card_img_left {
        width: auto;
    }

    .black_metal_section .left_under_black {
        width: 100%;
    }

    .related-produt .caro_item_related {
        width: 230px;
    }

    .related-produt .under_item {
        width: 230px;
    }

    .black_metal_section .for_me .row {
        width: auto;
    }

    .black_metal_section .black_meatl_first {
        padding-right: 0;
    }

    .digitalia_card .digitalia .card_sec_digitalia .digi_card_sub {
        text-align: center;
    }

    .product_section .cost_btn button {
        width: 135px;
    }

    .product_section .carosel_sec {
        margin-bottom: 0 !important;
        padding-bottom: 0 !important;
        padding-top: 0 !important;
    }

    .about_section .about_sec p {
        text-align: center;
    }

    .works_section {
        width: 100%;
    }

    .footer_lists {
        width: 50% !important;
    }

    .bulk_order_sec {
        padding: 80px 0px 80px;
    }

    .works_section {
        padding: 80px 0px;
    }

    .about_section .about_sec button {
        width: 100%;
        margin-left: 0;
    }
}

@media (max-width:430px) {
    .website_btn1 a {
        margin: auto;
    }

    .black_metal_section .card_img_left {
        width: 405px;
    }

    .related-produt .caro_item_related {
        width: 100%;
    }

    .related-produt .under_item {
        width: 100%;
    }

    .related-produt .react-multiple-carousel__arrow--left {
        left: 71%;
    }

    .black_metal_section .card-detail {
        padding-left: 0;
    }

    .explore_sec {
        padding: 50px 0px;
    }

    .digitalia_card {
        padding: 50px 0px;
    }

    .product_section {
        padding-top: 50px;
    }

    .product_section .tab_inner_page h4 {
        text-align: center;
    }

    .product_section .tab_inner_page p {
        text-align: center;
        justify-content: center;
    }

    .about_section {
        padding: 50px 0px;
    }

    .about_section .about_sec h2 br {
        display: none;
    }

    .about_section .about_card_pic {
        margin-top: 50px;
    }

    .about_section .about_img2 {
        margin-top: 50px;
    }

    .virtual_sec {
        padding: 50px 0px;
    }

    .virtual_sec .virtual_card_content h2 {
        text-align: center;
    }

    .virtual_sec .virtual_card_content h3 {
        text-align: center;
    }

    .virtual_sec .virtual_card_content p {
        text-align: center;
    }

    .virtual_sec .virtual_card_content button {
        width: auto;
        margin-top: 20px;
    }

    .works_section {
        padding: 50px 0px;
    }

    .works_section h2 {
        padding-bottom: 20px;
    }

    .works_section .video_part {
        padding-top: 0;
    }

    .pricing_sec {
        padding: 50px 0px;
    }

    .bulk_order_sec {
        padding: 50px 0px 50px;
    }

    .testimonials_sec .testimonials {
        padding: 50px 0px;
    }

    .get_start_sec {
        padding-top: 50px;
    }

    .demo_sec {
        padding: 50px 0px;
    }

    .footer_section .footer_ul {
        margin-bottom: 0px;
    }

    .product_sec .react-multiple-carousel__arrow--left {
        left: 71% !important;
    }

    .product_sec .realted_head_text {
        font-size: 26px !important;
    }

    .digitalia_sec .card_sub_1 h5 {
        text-align: center;
    }

    .digitalia_sec .digital_card_btn_2 {
        width: 100%;
        margin: 0;
    }

    .digitalia_sec .digital_card_btn_1 {
        width: 100%;
        margin: 0px 0 10px 0px;
    }

    .back-img-color {
        padding-bottom: 80px !important;
    }

    .contact_sec .contact_text1 {
        padding-bottom: 35px;
    }

    .contact_sec {
        padding-bottom: 50px;
    }
}

@media (max-width:428px) {

    .about_login_sec .react-multiple-carousel__arrow--left {
        left: 41% !important;
    }
}

@media (max-width:414px) {

    .black_metal_section .card_img_left {
        width: 100%;
    }

    .footer_section .footer_content {
        width: 100%;
    }
}

@media (max-width:390px) {

    .footer_section .footer_ul li {
        padding: 5px 30px;
    }

    .about_section .about_sec p {
        margin-bottom: 20px;
    }

    .virtual_sec .virtual_card_content h2 br {
        display: none;
    }

    .about_page_sec .about_part {
        padding: 50px 0px;
    }

    .explore_sec {
        padding: 30px 0px;
    }

    .about_login_sec .caro_item {
        padding: 50px 0px;
    }

    .about_login_sec .react-multiple-carousel__arrow--left {
        left: 40% !important;
    }

    .back-img-color {
        padding-bottom: 50px !important;
    }

    .about_bulk_sec {
        padding: 50px 0px !important;
    }
}

@media (max-width:376px) {

    .black_metal_section .card_img_left {
        width: 100%;
    }

    .black_metal_section .under_black_metal {
        display: block;
    }

    .black_metal_section .left_under_black {
        width: 100%;
    }

    .related-produt .react-multiple-carousel__arrow--left {
        left: 69%;
    }

    .product_sec .react-multiple-carousel__arrow--left {
        left: 64% !important;
    }

    .testimonials_sec .testimonials .caro_item {
        height: auto;
    }
}

@media (max-width:320px) {

    .related-produt .react-multiple-carousel__arrow--left {
        left: 63%;
    }

    .explore_sec h2 br {
        display: none;
    }

    .digitalia_card .digitalia h2 br {
        display: none;
    }

    .digitalia_card .digitalia h2 {
        padding-bottom: 20px;
    }

    .product_section .nav-fill .nav-item,
    .nav-fill>.nav-link {
        margin-top: 10px;
    }

    .product_section h2 {
        padding-bottom: 0px;
    }

    .virtual_sec .virtual_image {
        height: auto;
    }

    .virtual_sec .virtual_card_content h2 br {
        display: none;
    }

    .virtual_sec .virtual_card_content h2 {
        padding-bottom: 10px;
    }

    .virtual_sec .virtual_card_content h3 {
        padding-bottom: 0;
    }

    .footer_section .footer_content ul li {
        padding: 10px 0px;
    }

    .about_login_sec .react-multiple-carousel__arrow--left {
        left: 37% !important;
    }

    .product_sec .react-multiple-carousel__arrow--left {
        top: 54px;
        left: 60%;
    }

    .product_sec .react-multiple-carousel__arrow--right {
        top: 54px;
    }

    .product_sec .react-multiple-carousel__arrow--left {
        left: 67% !important;
    }

    .black_metal_section .nav-tabs .nav-item .nav-link {
        margin-top: 20px;
    }

    .black_metal_section {
        padding: 50px 0px;
    }
}


/* pricing plans css */

.pricing_plans {
    max-width: 1680px;
    margin: auto;
}

.pricing_plans {
    background-color: #212121;
    padding: 105px 0px;
}

.pricing_plans .boxes {
    width: 81%;
    margin: auto;
}

.pricing_plans h3.pricing_plans_txt {
    text-align: center;
    font-size: 30px;
    font-family: Poppins-SemiBold;
    margin-bottom: 20px;
    color: #fff;
}

.pricing_plans p.pricing_plans_para {
    text-align: center;
    font-size: 18px;
    font-family: Poppins-Regular;
    margin-bottom: 65px;
    color: #fff;
    width: 80%;
    margin: auto;
    padding-bottom: 45px;
}

.pricing_plans h4.individuals_txt {
    font-size: 24px;
    font-family: Poppins-SemiBold;
    margin-bottom: 20px;
    color: #fff;
}

.pricing_plans h4.premium_txt {
    font-size: 24px;
    font-family: Poppins-Regular;
    margin-bottom: 0;
    color: #fff;
}

.pricing_plans .premium {
    border-bottom: 1px solid #fff;
    width: max-content;
}

.pricing_plans h5.premium_amount {
    font-size: 35px;
    font-family: Poppins-Light;
    margin-top: 10px;
    color: #fff;
}

.pricing_plans h5.premium_amount span.month_txt {
    font-size: 18px;
}

.pricing_plans li.premium_list {
    font-size: 18px;
    font-family: Poppins-Regular;
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #fff;
}

.pricing_plans ul.premium_unorder {
    padding-left: 0;
    margin-top: 15px;
}

.pricing_plans span.dots_list {
    height: 10px;
    display: inline-block;
    width: 10px;
    background-color: #00aeef;
    border-radius: 50%;
    margin-right: 10px;
}

.pricing_plans button.order_now_btn {
    background-color: #00aeef;
    border: none;
    font-size: 16px;
    font-family: Poppins-SemiBold;
    padding: 10px 30px;
}
.pricing_plans button.order_now_btn:active{
    background-color: #00aeef;
}
.pricing_plans button.order_now_btn:hover {
    background-color: #00aeef;
}

.pricing_plans .pricing_box_sec {
    border: 1px solid #00aeef;
    border-radius: 5px;
    position: relative;
    margin-right: 20px;
}

.pricing_plans .order_now_btn2 {
    margin-top: 27px;
}

.pricing_plans .individual_box {
    padding-left: 34px;
    padding-top: 45px;
    padding-bottom: 55px;
}

.pricing_plans span.icon_bdr {
    position: absolute;
    left: -10px;
    top: 37px;
    color: #00aeef;
    font-size: 34px;
}

@media (max-width:1112px) {

    .pricing_plans li.premium_list {
        font-size: 16px;
    }
}


@media (max-width:991px) {

    .pricing_plans .pricing_box_sec {
        margin-top: 30px;
        margin-right: 0;
    }

    header .navbar-toggler:focus {
        box-shadow: none;
    }
}

@media (max-width:430px) {

    .pricing_plans {
        padding: 60px 0px;
    }
}

@media (max-width:414px) {

    .pricing_plans p.pricing_plans_para {
        padding-bottom: 30px;
    }
}

@media (max-width:390px) {

    .explore_sec p {
        padding-top: 10px;
    }

    .about_section .about_sec .about_ul li {
        font-size: 16px;
    }

    .testimonials_sec .testimonials .prof_name {
        position: unset;
    }
}

/* login css */

.login {
    max-width: 1680px;
    margin: auto;
}

.login {
    background-color: #f7f5fc;
}

.login_div {
    background-color: #212121;
    border-radius: 25px;
    padding-bottom: 25px;
}

.login .left-login {
    padding-top: 35px;
    padding-left: 30px;
    padding-bottom: 35px;
    width: 500px;
}

.login .left-login img {
    width: 100%;
}

.login h4.login_txt {
    font-size: 26px;
    font-family: Poppins-SemiBold;
    text-align: center;
    margin-bottom: 15px;
    color: #fff;
}

.login .right-login {
    padding-top: 75px;
    padding-left: 30px;
    padding-right: 70px;
}

.login .form-control {
    font-family: Poppins-Regular;
    font-size: 15px;
    height: 50px;
    border: none;
}

.login .form-control:focus {
    box-shadow: none !important;
    border: none !important;
}

.login button.login_txt {
    width: 100%;
    background-color: #00aeef;
    border: none;
    padding: 10px 0px;
    font-size: 20px;
}

.login .forgot_password {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
}

.login p.forgot_txt {
    font-size: 14px;
    font-family: Poppins-Regular;
    color: #fff;
}

.login a.click_txt {
    color: #00aeef;
    text-decoration: none;
    cursor: pointer;
    margin-left: 5px;
}

@media (max-width:1112px) {

    .login .left-login {
        width: 450px;
    }

    .login p.forgot_txt {
        font-size: 13px;
    }
}

@media (max-width:991px) {
    header .nav-link {
        padding: 0;
    }

    header .nav-link {
        margin: 20px 0px 0px;
    }

    a.login_btn {
        margin-left: 0;
        border-radius: 0px;
    }

    header .acw_navbar .order_card button {
        padding: 5px 0px !important;
    }

    .active_header {
        background-color: unset !important;
        color: #2f2f2f !important;
        padding: 5px 0px !important;
        border-bottom: 1px solid !important;
        width: 100%;
    }

    .footer_section .footer_content ul li {
        font-size: 13px;
        padding: 10px 0px;
    }

    header .nav-link {
        font-size: 16px;
    }

    header .acw_navbar .login_btn button {
        font-size: 16px;
    }

    header .acw_navbar .order_card button {
        font-size: 16px;
    }

    .banner_order_sec {
        padding-bottom: 60px;
        padding-top: 60px;
    }

    .login .left-login {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
        /* display: none; */
    }

    .login .right-login {
        padding-top: 40px;
        padding-left: 30px;
        padding-right: 30px;
    }
}

@media (max-width:430px) {

    .login .forgot_password {
        display: block;
    }

    .black_metal_section .nav-tabs .nav-item .nav-link {
        margin-bottom: 10px;
    }
}

@media (max-width:320px) {

    .login h4.login_txt {
        font-size: 19px;
    }
}

.error-message {
    color: #FF0000;
    font-size: 11px;
    margin-bottom: 20px;
}

.red_bdr .css-13cymwt-control {
    border-color: #FF0000 !important;
    box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
}

.red_bdr {
    border-color: #FF0000 !important;
    box-shadow: rgba(100, 100, 111, 0.4) 0px 7px 29px 0px;
}

.css-13cymwt-control {
    border-radius: 12px !important;
    text-align: left;
    font-size: 1rem;
    font-weight: 400 !important;
    line-height: 1.5 !important;
    color: var(--bs-body-color) !important;
    border: var(--bs-border-width) solid var(--bs-border-color);
    box-sizing: unset !important;
    height: 40px !important;
}

.login .form-control {
    border-radius: 5px !important;
}

.css-t3ipsp-control {
    min-height: 40px !important;
    border-radius: 12px !important;
}

.css-t3ipsp-control,
.css-1nmdiq5-menu {
    text-align: left;
}

.css-t3ipsp-control:hover {
    /* border-color: var(--bs-border-width) solid var(--bs-border-color) !important; */
}

.css-t3ipsp-control {
    border-color: #dee2e6 !important;
    box-shadow: unset !important;
}

.logo-ai {
    position: fixed;
    top: 0px;
    width: 100%;
    background-color: white;
    z-index: 9999;
}

.navbar_sec .navbar-light .navbar-nav .nav-link {
    color: #2f2f2f;
}

.navbar_sec .btn-primary:active {
    background-color: #00aeef !important;
}

.demo_sec .demo .select-placeholder-text {
    color: #BABDBF;
    font-family: Poppins-Regular;
    font-size: 16px;
}
/* ======ccss */

@media only screen and (min-width: 1450px) {

    .testimonials_sec .testimonials .caro_item {
        width: auto;
    }

    .black_metal_section .left_under_black {
        width: 170px;
    }
    img.card-img-top{
        height: 300px !important;
    }
    .related-produt .caro_item_related img{
        height: 300px !important;
    }
   
}
@media only screen and (min-width: 1650px) {
    .black_metal_section .left_under_black{
        width: 180px !important;
    }
    img.card-img-top {
        height: 300px;
    }
    .related-produt .caro_item_related img{
        height: 200px;
    }
}
@media only screen and (min-width: 1300px) {
    .carosel_sec .caro_item {
       width: 100% !important;
    }
    .about_login_sec .react-multiple-carousel__arrow--right{
        right: 65%;
    }
    /* .related-produt .caro_item_related img{
        height: 300px !important;
    } */
    .digitalia_sec .digital_card_btn_1{
        width: 125px !important;
    }
}
@media only screen and (min-width: 1200px) {
    .about_page_sec .aboutcard_card_img{
        height: auto !important;
    }
    .about_page_sec .aboutcard_card_img img{
        object-fit: cover !important;
    }
    img.card-img-top{
        height: 230px;
    }
    .related-produt .caro_item_related img{
        height: 230px;
    }
    .digitalia_card_design{
        margin: 10px;
    }
    .carosel_sec .caro_item {
        height: 170px;
        margin: 10px;
    }
    .related-produt .caro_item_related{
        width: 100% !important;
    }
    .related-produt .under_item{
        width: 100% !important;
    }
   
    
    /* .black_metal_section .left_under_black {
        width: 180px;
    } */
    
}
@media only screen and (min-width: 1100px) {
    .digitalia_sec .digital_card_btn_1{
        width: 125px !important;
    }
    .about_login_sec .react-multiple-carousel__arrow--right {
        right: 60%;
    }
}
@media only screen and (max-width: 1300px) {
    .black_metal_section .left_under_black {
        width: 128px !important;
    }
    
}
@media only screen and (max-width: 1164px) {
    header .nav-link {
        font-size: 12px;
    }
    .enquiry_image_right {
        margin-left: 20px;
    }
    .black_metal_section .left_under_black{
        width: 145px;
    }

    .digitalia_sec .digitalia_card_design .card {
        width: 352px;
    }

    header .acw_navbar .login_btn button {
        font-size: 12px;
    }

    header .acw_navbar .order_card button {
        font-size: 12px;
    }

    header .acw_navbar {
        align-items: center;
    }
}

@media only screen and (max-width: 1112px) {

    .digitalia_card .digitalia .card_sec_digitalia .digi_card_sub {
        font-size: 22px;
    }

    .last_card_div {
        align-items: unset;
        margin-top: 90px !important;
    }

    .testimonials_sec .testimonials .caro_item {
        width: auto;
    }
}

@media only screen and (max-width: 1024px) {

    .about_section .mob_pic {
        right: 106px;
    }

    .carosel_sec .caro_item {
        height: auto;
    }
}

@media only screen and (max-width: 1000px) {
    header .acw_navbar {
        align-items: start;
    }

    .order_section {
        width: 100%;
    }

    .explore_sec p {
        text-align: justify;
    }

    .explore_sec {
        width: 100%;
    }

    .product_section .cost_btn {
        flex-direction: column;
    }

    .about_section .about_sec {
        width: 100%;
    }

    .works_section {
        width: 100%;
    }

    .bulk_order_sec .bulk_order {
        width: 100%;
    }

    .get_start_sec .left_cre {
        text-align: center;
    }

    .demo_sec .demo {
        width: 100%;
    }

    .copyrighs_section .copy_rights {
        flex-direction: column;
        text-align: center;
    }

    .copyrighs_section .copy_rights .social_media {
        padding: 15px 0px;
    }

    .footer_section .footer_ul li {
        padding: 10px 30px;
    }

    .get_start_sec .right_start {
        height: auto;
        margin-top: 30px;
    }

    .product_section .product_card_image {
        height: auto;
    }

    .digitalia_card .digitalia .card_sec_digitalia .digi_card_img {
        height: auto;
    }

    .product_section .tab_inner_page h4 {
        margin-top: 20px;
    }

    .digitalia_card .digitalia .card_sec_digitalia .digi_card_sub {
        margin-top: 20px;
    }

    .virtual_sec .virtual_card_content h2 {
        margin-top: 20px;
        text-align: center;
    }

    .pricing_content_text h2 {
        margin-top: 20px;
        text-align: center;
    }

    .about_section .about_sec h2 {
        text-align: center;
    }

    .works_section .video_part {
        height: auto;
    }

    .pricing_sec .pricing_content .pricing_images {
        height: auto;
    }

    .product_section .tab_inner_page p {
        text-align: center;
        margin-bottom: 0px;
    }
    .product_section .cost_btn{
        margin-bottom: 25px;
    }

    .virtual_sec .virtual_card_content .virtual_btns {
        text-align: center;
    }
}


@media only screen and (max-width: 992px) {
    .works_section .video_part{
        width: 100%;
    }

    .about_section .mob_pic {
        right: 120px;
    }

    .virtual_sec .virtual_card_content h2 {
        text-align: left;
    }
}

@media only screen and (max-width: 991px) {
    .react_videoplayer_innerpage {
        height: auto;
    }

    .react_videoplayer_innerpage video {
        height: auto;
    }

    .last_card_div {
        margin-top: 0 !important;
    }

    .digitalia_card .digitalia .card_sec_digitalia .digitalia {
        margin-bottom: 0;
    }

    .product_section .tab_inner_page p {
        text-align: left;
    }

    .about_section .mob_pic {
        position: unset;
    }

    .pricing_content_sub {
        text-align: center;
    }

    .pricing_content_box {
        width: 100%;
        text-align: center;
    }

    .pricing_content_text button {
        width: 100%;
    }
}

@media only screen and (max-width: 820px) {

    .about_section .mob_pic {
        width: 100%;
    }

    .about_section .about_img2 {
        height: auto;
        margin-top: 75px;
    }


}