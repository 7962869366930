.center-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #aee5ff;

    @media (max-width: 768px) {
        /* Adjust styles for mobile screens */
    }
}

.error-page {
    text-align: center;
}

.oops-para {
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: baseline;
    font-weight: 500;
}

.oops-para span {
    padding-left: 3px;
}